import Resizer from "react-image-file-resizer";

export function base64ToFile(base64, nomeArquivo, id) {
  const byteString = atob(base64.split(",")[1]);
  const mimeString = base64.split(",")[0].split(":")[1].split(";")[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([ab], { type: mimeString });
  const file = new File([blob], nomeArquivo, { type: mimeString });

  file.id = id;

  return file;
}
export function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

export const convertWebPToPNG = (webpBase64) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = webpBase64;

    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);

      const pngBase64 = canvas.toDataURL("image/png");
      resolve(pngBase64);
    };

    img.onerror = (error) => {
      reject(error);
    };
  });
};

export const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      "WEBP",
      60,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

export const formatFileName = (name) => {
  return name
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[^a-z\s]/g, "")
    .split(" ")
    .slice(0, 4)
    .join("-");
};