import axios from 'axios'
import { API_BASE_URL } from 'configs/AppConfig'
import history from '../history'
import { notification } from 'antd';
import { AUTH_TOKEN, REFRESH_TOKEN } from 'constants/AuthConstant';
import { useAuth } from 'stores/auth.store';

const service = axios.create({
	baseURL: API_BASE_URL,
	timeout: 600000
})

const ENTRY_ROUTE = '/auth/login';
const TOKEN_PAYLOAD_KEY = 'authorization';
const PUBLIC_REQUEST_KEY = 'public-request';

service.interceptors.request.use(
	config => {
		const jwtToken = localStorage.getItem(AUTH_TOKEN);

		if (jwtToken) {
			config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${jwtToken}`;
		}

		if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
			history.push(ENTRY_ROUTE);
			window.location.reload();
		}

		return config;
	},
	error => {
		notification.error({
			message: 'Error',
		});
		Promise.reject(error);
	}
);

// API respone interceptor
service.interceptors.response.use(
	response => {
		return response;
	},
	async (error) => {
		const originalRequest = error.config;
		if (error?.response?.status === 401 && !originalRequest._retry) {
			originalRequest._retry = true;

			const refreshToken = localStorage.getItem(REFRESH_TOKEN);
			if (refreshToken) {
				try {
					const response = await axios.post(`${API_BASE_URL}/auth/refresh-token`, {
						refreshToken,
					});
					if (response && response.data) {
						localStorage.setItem(AUTH_TOKEN, response.data.token);
						localStorage.setItem(REFRESH_TOKEN, response.data.refreshToken);

						axios.defaults.headers.common["Authorization"] =
							"Bearer " + response.data.token;

						return service(originalRequest);
					}
				} catch (refreshError) {
					const logout = useAuth.getState().logout;
					logout();
				}
			} else {
				const logout = useAuth.getState().logout;
				logout();
			}
		}

		return Promise.reject(error);
	}
);

export default service